<template>
  <div class="footer">
      <div class="footer_container">
        <!-- <div class="pic_list">
            <div class="pic_title"><img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000000Jb0Y52vE2Nb0000UG8cN9L000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=wFhzY674Phd7BfnzrYSHK%2BFr2NI%3D" alt=""></div>
        </div> -->
        <div class="flex_warp">
            <a class="pic_item" href="https://www.facebook.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000001kVXZi2vE2Nb0000UG8cw3f000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=40YYujyWGgZIg0k2dancCFJBm%2BE%3D" alt="">
            </a>
            <a class="pic_item" href="https://x.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000001t3Gm82vE2Nb0000UG8dAGm000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=DTYwjPMkJK1TjO2gSjiBQHm8Moo%3D" alt="">
            </a>
            
            <a class="pic_item" href="https://www.tiktok.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000000brEK22vE2Nb0000UG8cd7q000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=b53admzqeNUEHPwtYgm4w43AtfU%3D" alt="">
            </a>
            <a class="pic_item" href="https://www.instagram.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000000a6xpW2vE2Nb0000UG8dMb4000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=vmxd%2FLTa%2BaCxEkZZLv%2FuvI77VjE%3D" alt="">
            </a>
            <div class="pic_item"></div>
            <a class="pic_item" href="https://www.reddit.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/0000029BE8b2vE2Nb0000UG8dIHH000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=z4GuG2lSgZHLtC0b2k7Ur0nrcYU%3D" alt="">
            </a>
            <a class="pic_item" href="https://www.instagram.com" target="_blank">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000002CmDQu2vE2Nb0000UG8d2VR000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=3Y%2Fvl16Ci4ysNbRV1P1NxHFfs18%3D" alt="">
            </a>
           
        </div>
        
        <div class="bottom_tab">
              <router-link class="tab_link" to="/trems">User Agreement </router-link>
        
              <router-link class="tab_link" to='/aboutus'>About Us </router-link>
              <!-- <router-link class="tab_link" to='/fair'>Probability Fairness</router-link> -->
        </div>
          <div class="copyright">©2025 playsavefirst all rights reserved</div>
          <!-- <div class="copyright">Company Address: Guangdong Province Shenzhen City Futian District Bonded Area Fubao Street Fubao Community Hongmian Road 8, Yinda Digital Technology Park C Building 301F</div> -->
          <div class="copyright">Company Address: FLAT 1512，15/F，LUCKY CENTRE，NO.165-171 WAN CHAI ROAD,WAN CHAI HK</div>
          <div class="copyright">E-mail:  Customer-service@playsave1st.com</div>
          <!-- <div class="copyright">Phone:  +1（212）679-7433  &nbsp; +852 67497450</div> -->
          <!-- <div class="copyright" style="text-align: center;margin-top:30px;">JINOVATION INC</div> -->
          <div class="copyright" style="text-align: center;margin-top:30px;">HUAN SHENG TRADE LIMITED</div>
          <div class="flex">
            <div class="card_item">
                <img src="../assets/image/card.jpg" alt="">
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.footer{
    .pic_item{
        width: 20%;
    }
    .card_item{
        padding:10px 0 0;
        width: 60%;
    }
   padding:32px 16px;
    background:#f0f0f7;
    .footer_container{
        width:100%;
        text-align: left;
        .copyright{
            margin:5px auto;
            font-size:10px;
            color:#777584;
            margin-bottom:10px;
        }
        .bottom_tab{
            font-size:10px;
            color:#000;
            margin:20px auto;
           
            .tab_link{
                padding:0 5px;
                // border-left:1px solid #ccc;
                cursor: pointer;
                color:#000;
                // text-decoration-line: none;
                text-decoration-line: underline;
              
            }
        }
    }
}

</style>