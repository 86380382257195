<template>
  <div class="index"> 
        <home-header></home-header>
      
        <div class="grid_content" style="overflow:auto;-webkit-overflow-scrolling: touch;min-height: 100%;" v-infinite-scroll="loadMore" infinite-scroll-distance="1" :infinite-scroll-immediate="false" v-if="defaultList.length>0">
            <div class="head_swiper">
                <swiper :options="swiperOption" ref="mySwiper" class="swiper_content">
                    <swiper-slide class="slide_content" v-for="(i,index) in images" :key="index">
                    <img :src="i.url" :data-src="i.url" alt="" />
                </swiper-slide>
                </swiper>
                <div class="swiper-pagination pagination_state" slot="pagination"></div>
            </div>
            <div class="index_container" >
                <div class="pic_thumb">
                    <div class="pic_group" v-for="(item,index) in defaultList" :key="index" @click="toPage('/details?id='+item.categoryId)">
                      <img :src="item.src" alt="">
                      <!-- <div class="goods_title">{{ item.name }}</div> -->
                    </div>
                    <div class="pic_group">
                        <img src="../../assets/image/jieshao.jpg" alt="">
                    </div>
                    <div class="pic_group">
                        <img src="../../assets/image/gengduo.jpg" alt="">
                    </div>
                </div>
            </div>
            <!-- <p v-if="loading">loading...</p>
            <p v-if="!hasNextPage">No More</p> -->
            <home-footer></home-footer>
    
        </div>
        
  </div>
 
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import homeHeader from "../../components/header.vue";
import homeFooter from '../../components/footer.vue'

export default {
    // mixins:[langMixins],
    components:{
       swiper,
       swiperSlide,
       homeHeader,
       homeFooter
    },
    data(){
        return{
            goods:[],
            showGoods:[],
            name:'',
            swiperOption: {
                loop: true,
                autoplay: {
                delay: 2000,
                // stopOnLastSlide: false,
                // disableOnInteraction: false,
                },
                // freeMode: true,
                // 显示分页
                pagination: {
                el: ".swiper-pagination",
                clickable: true, //允许分页点击跳转
                },
                
               
            },
            images:[
                {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000001h7bfU2vE2Nb0000UHhNyGX000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=c8ZQvIkoQEGgoCmko3AtvDMNDSk%3D'},
                // {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000001QuOhg2vE2Nb0000UDIPyFh000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=zoDLBucSwXvxawFvqqsklutH4Y4%3D'},
                {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000001QuOhg2vE2Nb0000UDIPyFh000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=zoDLBucSwXvxawFvqqsklutH4Y4%3D'},
                {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000001ufCL02vE2Nb0000UCI7yyg000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=lReoaWCSUj3OSglHAPq2AVyTOOQ%3D'},
                // {url:banner2},
                // {url:banner1}
            ],
           
            bannerList:[],
            pageIndex:1,
            pageSize:20,
            goodsList:[],
            isLastPage:true,
            hasNextPage:true,
            loading:false,
            curreny:[
                {value:1,label:'USD',type:'$'}
            ],
            language:[
                {value:1,label:'EN'},
                {value:2,label:'IN'},
                {value:3,label:'JA'},
            ]
           
        }
    },
    created(){
   
    //    this.getData()
 
    },
    methods:{
        toPage(path){
            this.$router.push(path)
        },
        getData(){
            let _this = this
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let language = this.lang.dictValue
            this.loading = true
            let goodsList
            if(!language||language==undefined){   
                setTimeout(()=>{
                     _this.getData() 
                },1000)
                return
            }
           
            this.$axiosApi.getGoodsList({pageIndex,pageSize,language}).then(res=>{
                this.loading = false
                if(res.code == 200){
                    goodsList=this.goodsList.concat(res.data.list) 
                
                    this.goodsList = goodsList
                         
                    console.log('this.isLastPage,',this.isLastPage)
                    setTimeout(()=>{
                        this.isLastPage = res.data.isLastPage
                    },100)
                    
                    this.hasNextPage = res.data.hasNextPage  
                    console.log('res',this.goodsList)
                }
            })
        },
        getBanner(){
            let type=1
            this.$axiosApi.getBannerList({type}).then(res=>{
                if(res.code == 200){
                    this.bannerList = res.data
                }
            })
        },
        loadMore(){
            console.log('isLastPage',this.pageIndex+1,this.isLastPage)
            // return
            // if(!this.hasNextPage){return}
            if(this.isLastPage){return}
            this.pageIndex = this.pageIndex+1
            this.getData()
          
        },

        

    },
    computed:{
        defaultList(){return this.$store.state.defaultList},
        currenyOptions(){return this.$store.state.currenyOptions},
        lang (){console.log('lang',this.$store.state.lang);return this.$store.state.lang}
       
    }
    
}
</script>

<style lang='scss' scoped>
@import url(../../font/font.css);
.index{
    width:100%;
    position: relative;
    padding-top:60px;
    background:#1c192a;
        .pic_group{
            position: relative;
            .goods_title{
                position: absolute;
                font-size: 16px;
                bottom:8px;
                left:0;
                right:0;
                color: #fff;
            }
        }

    
    .head_swiper{
        width:100%;
        padding-bottom:20px;
        position: relative;
        .swiper_content{
            width:100%;
            .slide_content{
                width:100%;
            }
        }
        .swiper-pagination{
            width:100px;
            left:calc(50% - 50px);
            bottom:4px;
        }
        /deep/.swiper-pagination-bullet{
             background:#dedede;
             margin:0 3px;
             transition: all 0.2s ease-in-out;
             border-radius: 4px;
        }
        /deep/.swiper-pagination-bullet-active{
            background:#f77e1a;
            padding:0 2px;
        }
    }
  
}
</style>